import { Form } from "@quillforms/renderer-core";
import type { FormBlocks, FormMessages } from "@quillforms/types";
import "@quillforms/renderer-core/build-style/style.css";
declare module '@quillforms/react-renderer-utils';
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

import "./styles.css";
import preguntas from "./preguntas";
import { mensajes } from "./mensajes";
import React from "react";

registerCoreBlocks();
const App = () => {
  const apiKeyAirtable = process.env.REACT_APP_API_KEY_AIRTABLE;
  const baseId = 'app8afdeP1SxSAkKO';
  let tableId: string | undefined;

  const handleSubmit = async (formData, { completeForm, setIsSubmitting }) => { 
    setIsSubmitting(true);

  const urlParams = new URLSearchParams(window.location.search);
  const getLanding = urlParams.get('b');

  switch (getLanding) {
    case 'b-one':
      tableId = "tblS2SXzSsK7pRNp0";
      break;
    case 'b-two':
      tableId = "tblkX7HAiq5QAazIR";
      break;
    case 'b-three':
      tableId = "tblKMT5abQoMayLnj";
      break;
    default:
      tableId = "tblRmjQN0f1jwCfpA";
      break;
  }

    try { 
      const NameValue = formData.answers["Name"].value;
      const EmailValue = formData.answers["Email"].value;
  
      const datosFormulario = {
        fields: {
          "Name": NameValue,
          "Email": EmailValue,
        }
      };

      fetch(`https://api.airtable.com/v0/${baseId}/${tableId}`, {
          method: 'POST',
          headers: {
          'Authorization': `Bearer ${apiKeyAirtable}`, 
          'Content-Type': 'application/json'
       },
        body: JSON.stringify(datosFormulario)
        })
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.error(error));
            fbq('track', 'Lead', { datosFormulario });
            dataLayer.push({
              'event': 'registrationComplete'
             });            
            console.log('Datos enviados exitosamente a Airtable');
            completeForm();
            setIsSubmitting(false);
        } catch (error) {
          console.error("Error al enviar datos a Airtable:", error);
          setIsSubmitting(false);
        }
  };

  return (
    <div style={{ backgroundColor: "#ded0f0", width: "100%", height: "100vh" }}>
      <Form
        formObj={{
          blocks: preguntas as FormBlocks,
          messages: mensajes as FormMessages,
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          theme: {
            font: "Outfit",
            backgroundColor: "#ded0f0",
            buttonsBgColor: "#2a4940",
            logo: {
              src: "/kreivo-logo.svg",
            },
            questionsColor: "#000",
            answersColor: "#9063cd",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        beforeGoingNext={({
          currentBlockId,
          answers,
          goNext
        }) => {
          window.amplitude.track(`form:${currentBlockId}`, {answers,
            ...Array.from(new URLSearchParams(new URL(window.location.href).search).entries()).reduce((p, [k, v]) =>({ [k]:v , ...p}), {})})
          goNext();
        }}


        onSubmit={handleSubmit} applyLogic={false} />
    </div>
  );
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default App;