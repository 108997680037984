export const mensajes =
 {
    'label.button.ok': "Enviar",
    'label.hintText.enter': "Presiona Enter ↵ para continuar",
	'block.dropdown.placeholder': "Selecciona una opción",
	'block.shortText.placeholder': "Escribe tu respuesta aquí",
	'block.longText.placeholder': "Escribe tu respuesta aquí",
	'block.longText.hint': "<strong>Shift ⇧ + Enter ↵</strong> crean un salto de línea",
	'block.number.placeholder': "Escribe tu respuesta aquí",
	'block.email.placeholder': "Escribe tu email aquí",
	'block.defaultThankYouScreen.label': "¡Te has registrado exitosamente a Virto! \n\nTe mantendremos informado 🙏",
	'label.progress.percent': "{{progress:percent}}% completado",
	'label.errorAlert.required': "Campo obligatorio",
	'label.errorAlert.email': "Mail no válido",
	'label.errorAlert.maxCharacters': "Carácteres máximos alcanzados",
	'label.errorAlert.maxNum': "Por favor, ingresa un número menor a 9999",
	'label.errorAlert.selectionRequired': "Selecciona una opción",
	'block.dropdown.noSuggestions': "Selecciona una opción",
	'label.submitBtn': "Únete a nosotros",
};