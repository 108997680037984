  export default [
    {
      id: "person",
      name: "group",
      attributes: {
        label: "¡Gracias por completar estas preguntas!",
        attachment: {
          type: "image",
          url: "/img/group.svg",
        },
        layout: "split-right",
      },
      innerBlocks: [
        {
          name: "short-text",
          id: "Name",
          attributes: {
            layout: "split-right",
            required: true,
            label: "¿Cuál es tu nombre?",
          },
        }, 
        {
          name: "email",
          id: "Email",
          attributes: {
            required: true,
            label: "Por favor, ingresa tu correo electrónico.",
          },
        },
        {
          name: "statement",
          id: "thank-you",
          attributes: {
            label:"¡Gracias por tomarte el tiempo para completar este formulario!\n\n<strong>¡Estamos deseando darte la bienvenida al futuro de la innovación con Virto!</strong>",
            attachment: {
              type: "image",
              url: "/img/group.svg",
            },
            layout: "split-left",
          },
        },
      ]
    },
  ];
  